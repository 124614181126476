#questions2answers-link {
	float: right;
    border-left: 1px solid #515151;
}
#questions2answers-link a {
    display: block;
    color: #fff;
    font-weight: bold;
    padding: 8px 10px 11px 10px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    line-height: 18px;
}
#usefull_link_block li.q2aBox a:before {
	content: "\f059";
}
#q2a_contentBox {
	margin-bottom: 0;
}
#page .rte.q2a_formBox {
	padding-top: 20px;
	background: none;
	border: 1px solid #d6d4d4;
}
#q2a_form label {
	padding: 5px;
}
#q2a_form input {
	width: 95%;
	padding: 10px;
}
#q2a_form ul {
	padding-left: 25px;
}
#q2a_form .btn-small {
	width: 4%;
}
#q2a_form .q2a_errors {
	color: #f13340;
	background: #FDDFDF;
	padding: 10px;
	border: 1px solid #FB979E;
}
#q2a_formBox h3 {
	color: #3a3939;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 10px;
}

#q2a_formBox .post .post-head {
    border-bottom: 1px dashed #bdbdbd;
    color: #333;
    position: relative;
}
#q2a_formBox .post .post-infos {
	/*margin-top: 5px;*/
}
#q2a_formBox .post .post-infos .meta {
	background: #ddd;
	padding: 10px;
	border-bottom: 1px groove #f8f8f8;
}
#q2a_formBox .post .post-infos .avatar {
	width: 30px;
	height: 30px;
	display: block;
	margin-right: 15px;
}
#q2a_formBox .post .post-infos .meta .title {
	margin-bottom: 0;
}
#q2a_formBox .post .post-infos .meta .title a {
	color: black;
}
#q2a_formBox .post .post-infos .meta .title a:hover {
	color: #aaaaaa;
}
#q2a_formBox .post .post-infos .meta .time {
	margin-top: 8px;
	color: #999;
}
#q2a_formBox .post .post-infos .stats {
	margin-top: 15px;
}
#q2a_formBox .post .post-infos .stats .stat-item {
	display: inline-block;
	padding: 2px 10px;
}
#q2a_formBox .post .post-infos .stats .stat-item .icon {
	margin-right: 8px;
}
#q2a_formBox .post .post-image .image {
	width: 100%;
	height: auto;
}
#q2a_formBox .post .post-vote {
	width: 5%;
    padding: 2px 0 0 0;
    font-size: 18px;
    min-height: 100%;
    background: #f8f8f8;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}
#q2a_formBox .post .post-vote div {
	text-align: center;
}
#q2a_formBox .post .post-vote .vote-up {
	color: #358926;
}
#q2a_formBox .post .post-vote .vote-down {
	color: #7f2424;
}
#q2a_formBox .post .post-vote .vote-result {
	/*padding: 5px 0;*/
}
#q2a_formBox .post .post-vote .vote-result.up {
	color: #358926;
}
#q2a_formBox .post .post-vote .vote-result.down {
	color: #7f2424;
}
#q2a_formBox .post .post-description {
	width: 94%;
	min-height: 60px;
}
#q2a_formBox .post .post-vote .noLoggedVote {
	padding: 20px 0px;
    font-size: 34px;
}
#q2a_formBox .post .post-footer {
	padding: 15px;
}
#q2a_formBox .post .post-footer .input-group-addon a {
	color: #454545;
}
#q2a_formBox .post .post-footer .comments-list {
	padding: 0;
	margin-top: 20px;
	list-style-type: none;
}
#q2a_formBox .post .post-footer .comments-list .comment {
	display: block;
	width: 100%;
	margin: 20px 0;
	border-bottom: 1px dotted #ddd;
}
#q2a_formBox .post .post-footer .comments-list .comment.sublist {
	border: none;
}
#q2a_formBox .post .post-footer .comments-list .comment .img-contain {
	padding: 10px 0 0 7px;
}
#q2a_formBox .post .post-footer .comments-list .comment .avatar {
	width: 35px;
	height: 35px;
}
#q2a_formBox .post .post-footer .comments-list .comment .comment-heading {
	display: block;
	width: 100%;
}
#q2a_formBox .post .post-footer .comments-list .comment .comment-heading .user {
	font-size: 14px;
	font-weight: bold;
	display: inline;
	margin-top: 0;
	margin-right: 10px;
}
#q2a_formBox .post .post-footer .comments-list .comment .comment-heading .time {
	font-size: 12px;
	color: #aaa;
	margin-top: 0;
	display: inline;
}
#q2a_formBox .post .post-footer .comments-list .comment .comment-body {
	margin-left: 50px;
}
#q2a_formBox .post .post-footer .comments-list .comment .comment-body.bbottom {
	border-bottom: 1px dotted #ddd;
	background: aliceblue;
	padding: 10px 15px 0 15px;
	margin-bottom: 20px;
}
#q2a_formBox .post .post-footer .comments-list .comment.ajax {
	background: #B8DEAE;
}
#q2a_formBox .post .post-footer .comments-list .comment.ajax .comment-body.bbottom {
	background: none;
}
#q2a_formBox .post .post-footer .comments-list .comment > .comments-list {
	margin-left: 50px;
}
#q2a_formBox .post .post-footer .stats .btn-success {
	background: none;
	border-color: #3e9546;
	color: #46a74e !important;
	padding: 0 10px;
}
#q2a_formBox .post .post-footer .stats .btn-danger {
	background: none;
	border-color: #d43f3a;
	color: #d9534f !important;
	padding: 0 10px;
}

#q2a_formBox .post .post-footer .stats .btn-success:hover {
	background: #46a74e;
	color: #ffffff !important;
}
#q2a_formBox .post .post-footer .stats .btn-danger:hover {
	background: #d9534f;
	color: #ffffff !important;
}

#q2a_formBox blockquote {
	font-size: 12px;
	font-style: normal;
	margin: 18px 0 10px 45px;
	line-height: 1.45;
	padding-bottom: 23px;
	position: relative;
	border: none;
}
#q2a_formBox blockquote:before {
	display: block;
	content: "\201C";
	font-size: 80px;
	position: absolute;
	left: -40px;
	top: -27px;
}
#q2a_formBox blockquote cite {
	font-size: 14px;
	display: block;
	margin-top: 5px;
}
#q2a_formBox blockquote cite:before {
	content: "\2014 \2009";
}
#q2a_formBox blockquote a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: inline-block;
	text-decoration: none;
}
#q2a_formBox blockquote span.label {
	font-size: 10px;
	margin-top: -10px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

#q2a_formBox .open-comment {
	text-decoration: none;
	margin-top: -10px;
	display: block;
	color: #888888;
}

#q2a_formBox .arrow-down {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 7px solid steelblue;
	display: inline-block;
}
#q2a_formBox .arrow-up {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid steelblue;
	display: inline-block;
}
#q2a_formBox .hidden {
	display: none;
}

#q2a_formBox .q2a_answer_form button {
	border: none;
	background: none;
	padding: 0;
	margin: 0;
}






/*
|--------------------------------------------------------------------------
| Forum
|--------------------------------------------------------------------------
*/
.widget .panel-body { padding:0px; }
.widget .list-group { margin-bottom: 0; }
.widget .panel-title { display:inline }
.widget .label-info { float: right;padding: 2px 5px;-webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; }
.widget .title { font-size: 16px;font-weight: bold;color: #428bca;margin-bottom: 10px;display: inline-block; }
.widget li.list-group-item {border-radius: 0;border: 0;border-top: 1px solid #ddd;}
.widget li.list-group-item:hover { background-color: rgba(86,61,124,.1); }
.widget .mic-info { color: #666666;font-size: 11px; }
.widget .action { margin-top:5px; }
.widget .comment-text { font-size: 12px; }
.widget .btn-block { border-top-left-radius:0px;border-top-right-radius:0px; }
.widget .cat_link { color: #888888; }
.widget .prod_link { color: #555555; }
.widget .table {display: table; margin: 0 auto;}
.widget .pagination {margin: 7px 0;}
.widget .pagination>li { display: inline-flex; }
.widget .pagination>li.current, .widget .pagination>li.current span { background: #428bca;border:none;color:#fff; }
.widget .pagination>li>a, .widget .pagination>li>span { margin: 0 5px;width: 100%;}
.widget .bordert { border-top: 1px solid #ddd;background: #eeeeee; }

#page-forum .post-vote {
	font-size: 22px;
	min-height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
#page-forum .post-vote div {
	text-align: center;
}
#page-forum .post-vote .vote-up {
	color: #358926;
}
#page-forum .post-vote .vote-down {
	color: #7f2424;
}
#page-forum .post-vote .vote-result {
	padding: 5px 0;
}
#page-forum .post-vote .vote-result.up {
	color: #358926;
}
#page-forum .post-vote .vote-result.down {
	color: #7f2424;
}

#page #forum-item .rte.q2a_formBox {
	padding-top: 0;
}

#page #forum-item #q2a_formBox .post .post-vote {
	border: none;
}

#page #forum-item #q2a_formBox .post .post-infos {
	/*min-height: 45px;
	padding: 10px 0;
	margin-left: -30px;*/
}

#page #forum-item #q2a_formBox .post .prodimg {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
}
#page #forum-item #q2a_formBox .post .prodimg img {
	width: 60px;
}
#page #forum-item .q2a_errors {
	color: #f13340;
	background: #FDDFDF;
	padding: 10px;
	border: 1px solid #FB979E;
}

#page #forum-item .input-lg,
#page #q2a_formBox .input-lg {
	*padding: 15px;
	font-size: 12px;
	line-height: 1.3333333;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

#page #forum-item .post .post-description {
	width: 95%;
	margin-left: 5%;
}

#page-forum #q2a_formBox blockquote,
#page #forum-item #q2a_formBox blockquote {
	font-size: 16px;
	font-style: italic;
	margin: 10px 0 0 20px;
	line-height: 1.45;
	padding-bottom: 0;
	position: relative;
	border: none;
}
#page-forum #q2a_formBox blockquote:before,
#page #forum-item #q2a_formBox blockquote:before {
	display: block;
	content: "\201C";
	font-size: 80px;
	position: absolute;
	left: -20px;
	top: -20px;
}
#page #page-forum #q2a_formBox .post .post-vote,
#page #page-forum-item #q2a_formBox .post .post-vote {
	width: 5%;
    padding: 16px;
    font-size: 22px;
    min-height: 100%;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}


/*
|--------------------------------------------------------------------------
| Moreinfos
|--------------------------------------------------------------------------
*/

#q2a_moreinfos h3,
#q2a_settings h3 {
	font-size: 16px;
	border-bottom: none;
	margin: 0;
	padding: 0 0 17px 0;
}

#q2a_moreinfos .list-1 {
	list-style-type: disc;
	padding-left: 15px;
	margin-left: 20px;
}

#q2a_moreinfos .list-1 li {
	padding: 4px 0 6px 0;
	font-weight: bold;
}

#q2a_settings #q2a_settings_form {
	margin-left: 20px;
	margin-top: 20px;
}